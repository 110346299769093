import { LitElement, html } from "lit-element";
import { nothing } from "lit-html";
import { subscribe, unsubscribe } from "redux-subscribe";
import { URL_CLIENT_VIEW } from "../config/local";
import { noop } from "../core/actions/actions";
import { dayMonthYear } from "../services/dateHelper";
import { isQuoteLocked } from "../services/quoteHelper";

import store from "../store";
import linkIcon from "./svg/link";
import copyIcon from "./svg/copy";
import { copyToClipboard } from "../services/clipboard";

export class CatchmentPlanDetailsPanel extends LitElement {
    constructor() {
        super();
        this.quote = store.getState().quoteDetails;
        this.meta  = store.getState().quoteMeta;
        this.catchmentPlan = store.getState().catchmentPlan;

        store.dispatch(
            subscribe("quoteMeta", "updateMetaQuoteDetailsPanel", (data) => {
                this.meta = data.next;
                this.requestUpdate();
                return noop("updateMetaQuoteDetailsPanel");
            })
        );
        store.dispatch(
            subscribe("quoteDetails", "updateDetailsQuoteDetailsPanel", (data) => {
                this.quote = data.next;
                return noop("updateDetailsQuoteDetailsPanel");
            })
        );
        store.dispatch(
            subscribe("catchmentPlan", "updateCatchmentPlanQuoteDetailsPanel", (data) => {
                this.catchmentPlan = data.next;
                return noop("updateCatchmentPlanQuoteDetailsPanel");
            })
        );
    }

    static get properties() {
        return {
            quote: { type: Object },
            quoteMeta: { type: Object },
            catchmentPlan: { type: Object },
        };
    }

    disconnectedCallback() {
        store.dispatch(unsubscribe("quoteMeta", "updateMetaQuoteDetailsPanel"));
        store.dispatch(unsubscribe("quoteDetails", "updateDetailsQuoteDetailsPanel"));
        store.dispatch(unsubscribe("catchmentPlan", "updateCatchmentPlanQuoteDetailsPanel"));
    }

    renderClientView() {
        return html`
            <style>
                @import "main.css";
            </style>
            <div class="pt-4 px-4 flex flex-col">
                <h1 class="font-bold text-2xl text-peppermint">${this.meta.name}</h1>
                <span class="italic text-sm text-gray-500">Plan Quote ID: ${this.meta.id}</span>
                
                ${this.catchmentPlan.store_id ?
                    html`<span class="font-medium">${this.catchmentPlan.store_id} / ${this.catchmentPlan.store_name}</span>`
                    : nothing
                }
            </div>`;
    }

    copyQuoteIdToClipboard() {
        copyToClipboard(this.meta.id, "Quote ID was copied into your clipboard.");
    }

    render() {
        if (IS_CLIENT_APP) return this.renderClientView();

        return html`
            <style>
                @import "main.css";
            </style>
            <div class="relative border border-dark-grey rounded-sm px-3 py-2 mt-4 flex flex-col justify-between leading-normal">
                ${this.meta.id ? 
                    html`<a
                          href="${URL_CLIENT_VIEW}?${this.meta.id}"
                          target="_blank"
                          class="font-bold text-xl text-peppermint"
                          >${this.meta.name}&nbsp;${linkIcon}</a
                    >`
                    : html`<h1 class="font-bold text-xl">Untitled plan</h1>`}
                ${this.meta.id ?
                    html`<span class="flex items-center text-sm text-gray-500">
                            <span class="font-medium text-danger">Plan Quote ID:</span> 
                            <span class="pl-1 cursor-pointer hover:underline"
                                @click=${() => this.copyQuoteIdToClipboard()}>
                                ${this.meta.id}
                            </span>
                            <span class="pl-1 cursor-pointer text-sm text-peppermint" 
                                @click=${() => this.copyQuoteIdToClipboard()}>
                                ${copyIcon}
                            </span>
                        </span>`
                    : nothing
                }
                ${this.meta.updatedOn ?
                    html`<span class="italic text-sm">Last updated
                          ${dayMonthYear(
                              new Date(this.meta.updatedOn),
                              "-"
                          )}</span>`
                    : nothing
                }

                ${this.catchmentPlan.store_id ?
                    html`<span class="font-medium">${this.catchmentPlan.store_id} / ${this.catchmentPlan.store_name}</span>`
                    : nothing
                }

                ${isQuoteLocked(this.quote)
                    ? nothing
                    : html` 
                    <section class="flex flex-col pt-2">
                        <!-- Updated class -->
                        <div class="my-1">
                            <div class="flex flex-col">
                                    
                            </div>
                        </div>
                    </section>`
                }
            </div>
        `;
    }
}

global.customElements.define("rm-catchment-plan-details-panel", CatchmentPlanDetailsPanel);
