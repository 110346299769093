import { collection, doc, getDoc, getDocs, limit, orderBy, query, where } from "firebase/firestore";
import { firestore } from "./firebase/init";

import { setMarker } from "../services/marker";
import { fitTerritoryBound } from "./googleMap";
import { getCentroidAndBox, getRoundsByList } from "./httpApis/roundsQueries";

import {
    printingSpecsUpdate,
    quoteDetailsReset,
    quoteDetailsUpdate,
    quoteMetaUpdate,
    quoteMetaReset,
    roundsNetworkToggle,
    updateQuoteSettings,
} from "../core/actions/actions";
import {
    territoriesReset,
    territoryActivate,
    territoryDelete,
    territoryLoad,
    territoryUpdateLocation,
    territoryUpdateNetworkSelections,
    territoryUpdateVolumeSelections,
} from "../core/actions/territoryActions";
import store from "../store";

import { GMAP_ROUNDS_LAYERS, reduxInactiveRoundsUpdateEvent } from "../const/dataLayers";
import { VOLUME_SLICES } from "../const/volumeSlices";
import {
    getActiveTerritory,
    getFirstTerritoryWithBoundingBox,
    getTerritoryById,
} from "../core/selectors/territorySelector";
const { POBOXES, COUNTERS } = GMAP_ROUNDS_LAYERS;
const { BUSINESS } = VOLUME_SLICES;

import { clientAckEvent } from "../components/toast";
import { loadTargetingFilters } from "../core/actions/targetingFilterActions";
import { getCustomer } from "./httpApis/customer";
import { totalQuote } from "./httpApis/totalQuote";
import { getUrlTerritoryId } from "./urlHeplers";
import { updateCatchmentPlan } from "../core/actions/planActions";

const quoteCollRef = collection(firestore, "quotes");

export const isQuoteId = (id) => {
    // check if it conforms to our expectations (search string includes question mark)
    // Firestore default document ID is 20 characters long
    return 20 === id.length;
};

export const loadAllQuotes = async () => {
    const snapshot = await getDocs(query(quoteCollRef, orderBy("quoteMeta.updatedOn", "desc"), limit(50)));

    return snapshot.docs.map((doc) => doc.data());
};

export const searchQuotesByTags = async (searchTags) => {
    // lowercase search tags to conform to our historical data
    searchTags = searchTags.map((tag) => tag.toLowerCase());

    const snapshot = await getDocs(
        query(
            quoteCollRef,
            where("quoteMeta.tags", "array-contains-any", searchTags),
            orderBy("quoteMeta.updatedOn", "desc"),
            limit(100)
        )
    );

    return snapshot.docs.flatMap((doc) => {
        const quote = doc.data();

        if (searchTags.every((tag) => quote.quoteMeta.tags.includes(tag))) {
            return quote;
        }
        return [];
    });
};

/**
 *
 * @param {String} quoteId
 * @returns {Promise} Resolves with a proper quote state.
 */
export const loadQuote = (quoteId) =>
    new Promise((resolve, reject) => {
        if (isQuoteId(quoteId)) {
            // create quote reference, we don't know if underlying doc exists though
            const quoteDocRef = doc(firestore, quoteCollRef.path, quoteId);
            getDoc(quoteDocRef)
                .then((quoteDoc) => {
                    if (quoteDoc.exists()) {
                        resolve(quoteDoc.data());
                    } else {
                        reject("Quote does not exist!");
                    }
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        } else {
            reject(`Quote ID [${quoteId}] is malformed!`);
        }
    });

/**
 *
 * @param {Object} state
 */
export const loadState = async (state, options = { loadCustomer: true, useQuoteSettings: false }) => {
    // reset
    store.dispatch(territoriesReset());
    store.dispatch(quoteDetailsReset());
    store.dispatch(quoteMetaReset());

    // collect current territories - it's only one expected after reset
    const removeTerritories = store.getState().territories.map((territory) => territory.id);

    // load cms customer
    if (options.loadCustomer) {
        state.quoteMeta.customer = state.quoteMeta.customerCmsCode
            ? state.quoteMeta.customer || (await getCustomer(state.quoteMeta.customerCmsCode))
            : null;
    }

    // load up state
    store.dispatch(quoteMetaUpdate(state.quoteMeta));
    store.dispatch(printingSpecsUpdate(state.printingSpecs));
    store.dispatch(quoteDetailsUpdate(state.quoteDetails));
    store.dispatch(roundsNetworkToggle(state.networkVisibility));
    store.dispatch(
        updateQuoteSettings({
            ...(state.settings || {}),
            ...(options.useQuoteSettings && { active: true }),
        })
    );
    store.dispatch(updateCatchmentPlan(state.catchmentPlan ?? null));

    // TODO: this might be reconsidered if performance will be too bad to load it in separate chunks
    // load territories individually
    for (let territory of state.territories) {
        // load COUNTERS if POBOXES is selected
        if (territory.networkSelections.includes(POBOXES) && !territory.networkSelections.includes(COUNTERS)) {
            territory.networkSelections.push(COUNTERS);
            store.dispatch(territoryUpdateNetworkSelections(territory.networkSelections));
            territory.volumeSelections[COUNTERS] = [...territory.volumeSelections[POBOXES]];
            territory.volumeSelections[COUNTERS].splice(territory.volumeSelections[COUNTERS].indexOf(BUSINESS), 1);
            store.dispatch(
                territoryUpdateVolumeSelections({
                    layer: COUNTERS,
                    slice: territory.volumeSelections[COUNTERS],
                })
            );
        }

        const validIds = await getRoundsByList(Object.keys(territory.rounds), true);
        const rounds = dropOldRounds(territory.rounds, validIds);

        // load up only up to date rounds
        store.dispatch(territoryLoad({ ...territory, rounds }));

        // load territory location
        const location = await updateLocation(territory);
        store.dispatch(territoryUpdateLocation(location, territory.id));
        territory.location = location;

        // temporary activate loaded territory
        store.dispatch(territoryActivate(territory.id));

        // focus on it if possible - this is to demo the loading progress
        if (territory.location.center) {
            setMarker(territory.location.center);
        }
    }
    // activate the territory as loaded
    store.dispatch(territoryActivate(state.activeTerritory));

    // focus on active
    const territoryWithBound =
        getTerritoryById(getUrlTerritoryId()) || getActiveTerritory() || getFirstTerritoryWithBoundingBox() || {};
    fitTerritoryBound(territoryWithBound);

    // load targeting filters
    if (state.targetingFilters) {
        // pre-process data to allow old format to be used
        // can be decomissioned in the future

        const filters = [];
        let filter;
        while ((filter = state.targetingFilters.pop())) {
            const groups = [];
            let group;

            while ((group = filter.groups.pop())) {
                group.variablesPool = group.variablesPool.map((variable) => variable.toLowerCase());
                group.variables = group.variables.map((variable) => variable.toLowerCase());
                groups.push(group);
            }

            filter.groups = groups;
            filters.push(filter);
        }
        // end of pre-processing

        store.dispatch(loadTargetingFilters(filters));
    }

    // now it is safe to cleanup territories
    for (let territoryId of removeTerritories) {
        store.dispatch(territoryDelete(territoryId));
        document.dispatchEvent(reduxInactiveRoundsUpdateEvent);
    }

    totalQuote().then((totalResult) =>
        store.dispatch(
            quoteDetailsUpdate({
                subtotal: totalResult.totalCost,
                minAmtAdjustment: totalResult.minAmtAdjustment,
                discount: totalResult.discount ?? 0,
            })
        )
    );

    return;
};

/**
 * Allows to clean up outdated rounds from the territory, returns new object that can replace
 * territory.rounds.
 *
 * This function also has a side effect - generates a notification event every time an outdated
 * round is found. It's up to the app how to deal with it.
 *
 * @param {Object} rounds
 * @param {Array} validIds
 */
const dropOldRounds = (rounds, validIds) => {
    return Object.keys(rounds).reduce((acc, roundId) => {
        if (validIds.includes(parseInt(roundId))) {
            return { ...acc, ...{ [roundId]: rounds[roundId] } };
        } else {
            document.dispatchEvent(
                clientAckEvent({
                    message: "Rounds refreshed, please save the quote!",
                })
            );
            return acc;
        }
    }, {});
};

const updateLocation = async (territory) => {
    const centroidAndBox = await getCentroidAndBox(territory);
    let location = territory.location;
    if (!(territory.location.center && territory.location.address)) {
        location = { ...location, center: centroidAndBox.centroid || null };
    }
    location.boundingBox = centroidAndBox.bound || null;
    return location;
};
